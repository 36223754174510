import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Box, Card, CardMedia } from '@mui/material';
import { db } from '../firebase/firebaseConfig';
import { collection, doc, getDoc } from "firebase/firestore";
import uploadMedia from '../firebase/uploadMedia';
import SwipeableViews from 'react-swipeable-views';
import { useSwipeable } from 'react-swipeable';
import Resizer from 'react-image-file-resizer'; 
import { fonts } from '../utils/fontsandthemes';
import '../styles/EventPage.css';
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import { deleteMedia } from '../services/mediaService'; // Import media service if necessary

const EventPage = () => {
  const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Default background color
  const { uniqueUrl } = useParams();
  const [eventData, setEventData] = useState(null);
  const [media, setMedia] = useState({ photos: [], videos: [] });
  const [index, setIndex] = useState(0);
  const [openPhotoViewer, setOpenPhotoViewer] = useState(false);
  const [user, setUser] = useState(null); // Track the current user
  const [loading, setLoading] = useState(true); // Track if Auth is still loading
  const auth = getAuth(); // Initialize Firebase Auth

  // Firebase Auth check to get the current user (only relevant for event planner)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Set the logged-in user
      }
      setLoading(false); // Mark auth as resolved
    });
    return () => unsubscribe(); // Clean up
  }, [auth]);

  // Check if the current user is the event planner
  const isEventPlanner = () => {
    return user && eventData && user.uid === eventData.userId;
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventRef = doc(collection(db, 'events'), uniqueUrl);
        const eventSnap = await getDoc(eventRef);
  
        if (eventSnap.exists()) {
          const data = eventSnap.data();
          setEventData(data);
  
          // Use the `backgroundColor` from Firestore if available
          setBackgroundColor(data.backgroundColor || '#ffffff'); // Default to white if no color is set
        } else {
          console.log('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };
  
    fetchEventData();
  }, [uniqueUrl]);
  

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });
  };

  const handleMediaUpload = async (e) => {
    const files = Array.from(e.target.files);
    try {
      const uploadedMediaUrls = await Promise.all(
        files.map(async (file) => {
          const mediaToUpload = file.type.startsWith('image/') ? await resizeImage(file) : file;
          const uploadedUrl = await uploadMedia(uniqueUrl, mediaToUpload);
          return { url: uploadedUrl, type: file.type.startsWith('image/') ? 'photo' : 'video' };
        })
      );

      const newPhotos = uploadedMediaUrls.filter((item) => item.type === 'photo').map((item) => item.url);
      const newVideos = uploadedMediaUrls.filter((item) => item.type === 'video').map((item) => item.url);

      setMedia((prevMedia) => ({
        photos: [...prevMedia.photos, ...newPhotos],
        videos: [...prevMedia.videos, ...newVideos],
      }));
    } catch (error) {
      console.error('Error uploading media:', error);
    }
  };

  const handlePhotoClick = (idx) => {
    setIndex(idx);
    setOpenPhotoViewer(true);
  };

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedDown: () => {
      setOpenPhotoViewer(false);
    },
  });

  const handleDelete = async (mediaUrl, mediaType) => {
    if (!isEventPlanner()) {
      console.log("Only event planners can delete media.");
      return;
    }

    try {
      // Call the media deletion logic
      await deleteMedia(uniqueUrl, mediaUrl, mediaType, user.uid, eventData.userId);
      setMedia((prevMedia) => ({
        ...prevMedia,
        [mediaType === 'photo' ? 'photos' : 'videos']: prevMedia[mediaType === 'photo' ? 'photos' : 'videos'].filter((url) => url !== mediaUrl),
      }));
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  const containerStyles = {
    fontFamily: eventData?.titleFont || 'Arial',
    fontSize: eventData?.titleFontSize || '20px',
    color: eventData?.titleFontColor || '#000',
    backgroundColor: backgroundColor, // Dynamically apply the background color
    padding: '20px',
    minHeight: '100vh',
  };
  

  const dateStyles = {
    fontFamily: eventData?.dateFont || 'Arial',
    fontSize: eventData?.dateFontSize || '14px',
    color: eventData?.dateFontColor || eventData?.theme?.secondaryColor || '#000',
    fontWeight: eventData?.dateBold ? 'bold' : 'normal',
  };

  const welcomeMessageStyles = {
    fontFamily: eventData?.welcomeFont || 'Arial',
    fontSize: eventData?.welcomeFontSize || '16px',
    color: eventData?.welcomeFontColor || eventData?.theme?.primaryColor || '#000',
    fontWeight: eventData?.welcomeBold ? 'bold' : 'normal'
  };

  const combinedMedia = [
    ...media.photos.map((url) => ({ url, type: 'photo' })),
    ...media.videos.map((url) => ({ url, type: 'video' }))
  ];

  const gridStyles = eventData?.gridStyle?.styles || {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '16px',
  };

  const renderMediaGrid = () => (
    <div style={{ ...gridStyles, display: 'grid' }}>
      {combinedMedia.map((item, idx) => (
        <Card
          key={idx}
          style={{ margin: '16px' }}
          onClick={() => item.type === 'photo' && handlePhotoClick(idx)}
        >
          {item.type === 'photo' ? (
            <CardMedia component="img" image={item.url} alt={`Media ${idx}`} />
          ) : (
            <CardMedia component="video" controls src={item.url} alt={`Media ${idx}`} />
          )}
          {/* Show delete button only if user is the event planner */}
          {!loading && isEventPlanner() && (
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(item.url, item.type)}
            >
              Delete
            </Button>
          )}
        </Card>
      ))}
    </div>
  );

  return (
    <Container style={containerStyles}>
      {/* Event information below the cover photo */}
      <div className="event-header">
        {eventData?.coverPhotoUrl && (
          <div className="cover-photo-container">
            <img src={eventData.coverPhotoUrl} alt="Cover" className="cover-photo" />
          </div>
        )}
    <Typography
  variant="h4"
  component="h1"
  className="event-title"
  style={{
    fontFamily: eventData?.titleFont || 'Arial',
    fontSize: eventData?.titleFontSize || '20px',
    color: eventData?.titleFontColor || '#000',
    textShadow: eventData?.titleShadow || 'none', // Apply title shadow
    fontWeight: eventData?.titleBold ? 'bold' : 'normal', // Apply bold state
  }}
>
  {eventData?.eventName}
</Typography>

        <Typography variant="subtitle1" className="event-date" style={dateStyles}>
          {eventData?.eventDate}
        </Typography>
        {eventData?.welcomeMessage && (
         <Typography
         variant="h6"
         component="h2"
         className="italic-message"
         style={{
           fontFamily: eventData?.welcomeFont || 'Arial',
           fontSize: eventData?.welcomeFontSize || '16px',
           color: eventData?.welcomeFontColor || '#000',
           fontWeight: eventData?.welcomeBold ? 'bold' : 'normal', // Apply bold state
         }}
       >
         {eventData?.welcomeMessage}
       </Typography>
       
        )}
      </div>

      {/* Single File Input for Photos and Videos */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <input
          type="file"
          accept="image/*,video/*"
          multiple
          onChange={handleMediaUpload}
          style={{ display: 'none' }}
          id="media-upload"
        />
       <Button
  variant="contained"
  style={{
    backgroundColor: eventData?.buttonColor || '#1976d2',
    boxShadow: eventData?.buttonGlow || 'none', // Apply button glow
  }}
  onClick={() => document.getElementById('media-upload').click()}
>
  Upload Photos/Videos
</Button>

      </Box>

      {/* Combined Media Grid */}
      <div className="media-gallery" style={{ marginTop: '30px' }}>
        {renderMediaGrid()}
      </div>

      {/* Swipeable Full-Screen Photo Viewer */}
      {openPhotoViewer && (
  <div
    onClick={(e) => {
      if (e.target === e.currentTarget) setOpenPhotoViewer(false); // Close on tap outside
    }}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    }}
  >
    {/* "X" Button */}
    <button
      onClick={() => setOpenPhotoViewer(false)}
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        fontSize: '20px',
        border: 'none',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      X
    </button>

    {/* Swipeable Photo Viewer */}
    <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
  {media.photos.map((photo, idx) => (
    <div
      key={idx}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <img
        src={photo}
        alt={`Photo ${idx}`}
        style={{
          maxWidth: '90%',
          maxHeight: '90%',
          objectFit: 'contain', // Ensures it fits without distortion
          borderRadius: '10px',
        }}
      />
    </div>
  ))}
</SwipeableViews>

  </div>
)}

    </Container>
  );
};

export default EventPage;
