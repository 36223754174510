import React, { useState } from 'react';
import { Container, Paper, Typography, Box, LinearProgress } from '@mui/material';
import EventDetailsForm from './EventDetailsForm';
import EventCustomizationForm from './EventCustomizationForm';
import EventPreviewForm from './EventPreviewForm';

const EventCreationForm = () => {
  const [step, setStep] = useState(1);
  const [eventDetails, setEventDetails] = useState({});
  const [customizationData, setCustomizationData] = useState({});

  const handleNextFromStep1 = (details) => {
    setEventDetails(details);
    setStep(2);
  };

  const handleNextFromStep2 = (customization) => {
    setCustomizationData(customization);
    setStep(3);
  };

  const handleBackToStep1 = () => setStep(1);
  const handleBackToStep2 = () => setStep(2);

  // Progress bar logic
  const progressValue = step === 1 ? 33 : step === 2 ? 66 : 100;

  return (
    <Container maxWidth="xl" sx={{ mt: 5, mb: 5, px: 6 }}>
      {/* Progress Bar */}
      <Box sx={{ mb: 3 }}>
  <LinearProgress
    variant="determinate"
    value={progressValue}
    sx={{
      height: 8,
      borderRadius: 5,
      backgroundColor: '#f0f0f0',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#4CAF50', // Green Color
      },
    }}
  />
</Box>

<Paper
  elevation={0}
  sx={{
    p: 8, // Increased padding for better spacing
    mx: 'auto', // Centered layout
    maxWidth: '85%', // Expand width further
    borderRadius: 10, // More rounded corners for a modern look
    backgroundColor: '#f9f9f9', // Slightly lighter background for contrast
    boxShadow: `
      0 12px 20px rgba(0, 0, 0, 0.1), 
      0 8px 12px rgba(0, 0, 0, 0.08),
      0 0 30px rgba(66, 165, 245, 0.15)
    `,
    border: '1px solid #e0e0e0', // Softer border
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)', // Slight zoom on hover for interaction
      boxShadow: `
        0 14px 24px rgba(0, 0, 0, 0.15), 
        0 10px 16px rgba(0, 0, 0, 0.12),
        0 0 36px rgba(66, 165, 245, 0.2)
      `,
    },
  }}
>



        {/* Title */}
        <Typography
          variant="h4"
          component="h1"
          sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center', color: '#333' }}
        >
          {step === 1
            ? 'Create Event - Step 1'
            : step === 2
            ? 'Customize Event - Step 2'
            : 'Event Preview - Step 3'}
        </Typography>

        {/* Step Forms */}
        {step === 1 && <EventDetailsForm onNext={handleNextFromStep1} />}
        {step === 2 && (
          <EventCustomizationForm
            eventDetails={eventDetails}
            onNext={handleNextFromStep2}
            onBack={handleBackToStep1}
          />
        )}
        {step === 3 && (
          <EventPreviewForm
            eventDetails={eventDetails}
            customizationData={customizationData}
            onBack={handleBackToStep2}
          />
        )}
      </Paper>
    </Container>
  );
};

export default EventCreationForm;
