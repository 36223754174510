import React from 'react';
import { Typography, Box, Container, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle, Save, UploadFile, QrCode2, HelpOutline } from '@mui/icons-material';

const Docs = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ borderRadius: 4, overflow: 'hidden' }}>
        {/* Header Section */}
        <Box
          sx={{
            background: 'linear-gradient(to right, #4a90e2, #98c93c)',
            padding: '40px 20px',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
            Event Planner Features and User Guidelines
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, opacity: 0.9 }}>
            Everything you need to know to run your event like a pro.
          </Typography>
        </Box>

        {/* Main Content */}
        <Box sx={{ padding: '30px' }}>
          {/* Event Planner Controls */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <CheckCircle sx={{ color: '#4caf50', mr: 1 }} /> Event Planner Controls
            </Typography>
            <Typography variant="body1">
              As an event planner, you have <strong>full control</strong> over the media uploaded by users during
              your event. This includes the ability to <strong>delete photos or videos</strong> to ensure the gallery remains
              professional and clean.
            </Typography>
          </Paper>

          {/* Saving Photos and Videos */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <Save sx={{ color: '#2196f3', mr: 1 }} /> Saving Photos and Videos
            </Typography>
            <Typography variant="body1">
              Guests can save memories directly to their devices. Simply view the media and use your device's save
              functionality to download the desired content.
            </Typography>
          </Paper>

          {/* Video Upload Limits */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <UploadFile sx={{ color: '#f57c00', mr: 1 }} /> Video Upload Limits
            </Typography>
            <Typography variant="body1">
              Users can upload videos up to <strong>10 seconds</strong> in length. This keeps the platform performing
              smoothly while allowing guests to share highlights.
            </Typography>
          </Paper>

          {/* Event Creation Workflow */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <QrCode2 sx={{ color: '#673ab7', mr: 1 }} /> Event Creation Workflow
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Follow these steps to set up your event gallery:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle sx={{ color: '#4caf50' }} />
                </ListItemIcon>
                <ListItemText primary="1. Create Event: Add event name, date, and welcome message." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle sx={{ color: '#4caf50' }} />
                </ListItemIcon>
                <ListItemText primary="2. Customize Event: Upload a cover photo and select your preferred theme." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle sx={{ color: '#4caf50' }} />
                </ListItemIcon>
                <ListItemText primary="3. Share Event: Generate a QR code or share the direct event link." />
              </ListItem>
            </List>
          </Paper>

          {/* QR Code Sharing */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <QrCode2 sx={{ color: '#009688', mr: 1 }} /> QR Code Sharing
            </Typography>
            <Typography variant="body1">
              Once your event is ready, share it effortlessly:
            </Typography>
            <ul style={{ marginLeft: '20px' }}>
              <li>Download the QR code as an image or PDF.</li>
              <li>Print it on table cards, posters, or invitations.</li>
              <li>Guests can upload photos instantly without logging in.</li>
            </ul>
          </Paper>

          {/* Need Help */}
          <Paper elevation={2} sx={{ p: 3, borderRadius: 2, textAlign: 'center' }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center' }}
            >
              <HelpOutline sx={{ color: '#ff5722', mr: 1 }} /> Need Help?
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              If you have any questions, contact us at <strong>markpelsone@elevateevent.co</strong>.
            </Typography>
          </Paper>
        </Box>
      </Paper>
    </Container>
  );
};

export default Docs;
