import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Sidebar from './components/Sidebar'; // Ensure Sidebar is imported
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import './App.css';
import Home from './routes/Home';
import EventCreationForm from './components/EventCreationForm';
import EventPage from './components/EventPage';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import FAQ from './components/FAQ';
import Subpage from './components/Subpage';
import PrivateRoute from './components/PrivateRoute'; // Ensure PrivateRoute is imported correctly
import TermsAndConditions from './components/TermsAndConditions';
import PrivatePolicy from './components/PrivatePolicy'; 
import EventDashboard from './components/EventDashboard';
import QRCodeTemplates from './components/QRCodeTemplates'; // Fixed import case sensitivity
import Docs from './components/Docs';
import logo from './assets/logo.svg';

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

const AppWrapper = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Unified anchor state
  const [menuType, setMenuType] = useState(null); // Tracks which menu is open ('resources' or 'account')
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setCurrentUser(user);
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        setHasSubscription(userDoc.exists() && userDoc.data().subscriptionStatus === 'active');
      } else {
        setCurrentUser(null);
        setHasSubscription(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleMenuClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setMenuType(type);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuType(null);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => navigate('/', { replace: true }))
      .catch((error) => console.error('Error signing out:', error));
    handleMenuClose();
  };

  const sendToCustomerPortal = async () => {
    try {
      setIsRedirecting(true); // Show the spinner
      const functions = getFunctions();
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      const { data } = await createPortalLink({ returnUrl: window.location.origin });
      window.location.assign(data.url); // Redirect to Stripe portal
    } catch (error) {
      console.error('Error redirecting to Stripe customer portal:', error);
      setIsRedirecting(false); // Hide the spinner if there's an error
    }
  };  
  
  const isPublicEvent = location.pathname.startsWith('/events/');

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
        {/* Spinner overlay for Stripe redirection */}
        {isRedirecting && (
         <Box
           sx={{
             position: 'fixed',
             top: 0,
             left: 0,
             width: '100%',
             height: '100%',
             backgroundColor: 'rgba(0, 0, 0, 0.5)',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             zIndex: 9999,
           }}
         >
           <CircularProgress size={60} sx={{ color: '#fff' }} />
         </Box>
       )}
   
      {!isPublicEvent && !isMobile && currentUser && <Sidebar isAuthenticated={!!currentUser} />} {/* Sidebar included */}
      <Box sx={{ flexGrow: 1, overflowX: 'hidden' }}>
        {!isPublicEvent && (
      <AppBar
      position="fixed"
      sx={{
        top: 0,
        left: 0,
        right: 0,
        background: location.pathname === '/signup' || location.pathname === '/signin' || location.pathname === '/subpage'
          ? 'linear-gradient(90deg, #2A73E8 0%, #B3D8FF 100%)' // Blue Gradient for Signup/Signin/Subpage
          : currentUser
          ? 'linear-gradient(to right, #4a90e2, #98c93c)' // Blue to Green Gradient for Logged In Users
          : 'white', // Default White for Others
    
        color: location.pathname === '/signup' || location.pathname === '/signin' || currentUser
          ? 'black'
          : 'black', // Text color for better contrast
        boxShadow: 'none',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
    
    
      
          <Toolbar>
            {/* Logo */}
            <IconButton edge="start" color="inherit" href="/">
              <Box
                component="img"
                src={logo}
                alt="Logo"
                sx={{ height: { xs: '50px', md: '80px' }, marginRight: '10px' }}
              />
            </IconButton>
        
            <Box sx={{ flexGrow: 1 }} />
            {currentUser ? (
              <>
                {/* Resources Menu */}
                <Button
                  color="inherit"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(e) => handleMenuClick(e, 'resources')}
                >
                  Resources
                </Button>
        
                {/* Account Menu */}
                <IconButton
                  color="inherit"
                  onClick={(e) => handleMenuClick(e, 'account')}
                >
                  <AccountCircleIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  component={Link}
                  to="/signup"
                  color="inherit"
                  sx={{
                    fontSize: '18px',
                    display: { xs: 'block', sm: 'block' },
                  }}
                >
                  Sign Up
                </Button>
                <Button
                  component={Link}
                  to="/signin"
                  color="inherit"
                  sx={{
                    fontSize: '18px',
                    display: { xs: 'block', sm: 'block' },
                  }}
                >
                  Sign In
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        
        )}

        {/* Menus */}
        {menuType === 'resources' && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => navigate('/faq')}>FAQ</MenuItem>
            <MenuItem onClick={() => navigate('/subpage')}>ROI Calculator</MenuItem>
          </Menu>
        )}
        {menuType === 'account' && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={sendToCustomerPortal}>Manage Account</MenuItem>
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        )}

        <Box
          sx={{
            width: '100%',
            paddingTop: { xs: '64px', md: '80px' }, // Fix for overlapping content
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/eventdashboard" element={<EventDashboard />} />
            <Route path="/create-event" element={<PrivateRoute element={EventCreationForm} />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/subpage" element={<Subpage />} />
            <Route path="/events/:uniqueUrl" element={<EventPage />} />
            <Route path="/qrcode-templates" element={<QRCodeTemplates />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivatePolicy />} /> {/* FIXED */}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
