import React, { useState } from 'react';
import { 
  Button, 
  Container, 
  Typography, 
  IconButton, 
  Grid, 
  Input, 
  Slider, 
  Box, 
  Card, 
  CardMedia, 
  Select, 
  MenuItem, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails 
} from '@mui/material';
import { PhotoCamera, ColorLens, ExpandMore } from '@mui/icons-material';
import { fonts, backgroundColors } from '../utils/fontsandthemes';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';



const EventCustomizationForm = ({ eventDetails, onNext, onBack }) => {
  // Background and Customization States
  const [backgroundColor, setBackgroundColor] = useState(backgroundColors[0].color); // Default to first background color
  const [customColor, setCustomColor] = useState('#ffffff'); // Default custom color
  const [rgbBuffer, setRgbBuffer] = useState({ r: 255, g: 255, b: 255 }); // Default RGB values
const [showColorOptions, setShowColorOptions] = useState(false); // Toggle color options
const [showRgbInputs, setShowRgbInputs] = useState(false); // Show RGB inputs on hover

  // Title Customization States
  const [titleFont, setTitleFont] = useState(fonts[0].name); // Default to first font
  const [titleFontSize, setTitleFontSize] = useState('20px'); // Default font size
  const [titleFontColor, setTitleFontColor] = useState('#000000'); // Default font color
  const [titleGlow, setTitleGlow] = useState('0px 0px 10px rgba(255, 255, 255, 0.75)'); // Default glow
  const [glowColor, setGlowColor] = useState('#ffffff'); // Default glow color
  const [titleShadow, setTitleShadow] = useState('0px 0px 5px rgba(0, 0, 0, 0.5)'); // Default shadow
  const [titleBold, setTitleBold] = useState(false); // Default to not bold

  const [dateFont, setDateFont] = useState(fonts[0].name);
  const [dateFontSize, setDateFontSize] = useState('14px');
  const [dateFontColor, setDateFontColor] = useState('#000000');
  const [dateBold, setDateBold] = useState(false); // Default to not bold


  const [welcomeFont, setWelcomeFont] = useState(fonts[0].name);
  const [welcomeFontSize, setWelcomeFontSize] = useState('16px');
  const [welcomeFontColor, setWelcomeFontColor] = useState('#000000');
  const [welcomeBold, setWelcomeBold] = useState(false); // Default to not bold


const [buttonColor, setButtonColor] = useState('#1976d2'); // Default button color (Material-UI primary blue)
const [buttonGlow, setButtonGlow] = useState('0px 0px 15px rgba(0, 122, 255, 0.6)'); // Default button glow
const [buttonGlowColor, setButtonGlowColor] = useState('#007aff'); // Default button glow color
const [glowIntensity, setGlowIntensity] = useState(15); // Default glow intensity

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoUrl, setCoverPhotoUrl] = useState('https://via.placeholder.com/150'); // Placeholder image

  const placeholderImages = [
    'https://via.placeholder.com/150?text=Photo+1',
    'https://via.placeholder.com/150?text=Photo+2',
  
  ];

  // Font Color Pickers
  const handleFontColorChange = (e, type) => {
    const color = e.target.value;
    if (type === 'title') setTitleFontColor(color);
    if (type === 'date') setDateFontColor(color);
    if (type === 'welcome') setWelcomeFontColor(color);
  };

  // Cover Photo Logic
  const handleCoverPhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverPhoto(file);
      const storage = getStorage(); // Initialize Firebase Storage
      const storageRef = ref(storage, `coverPhotos/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setCoverPhotoUrl(url);
    }
  };

   // Handle RGB Changes
   const handleRgbChange = (key, value) => {
    const clampedValue = Math.max(0, Math.min(255, parseInt(value, 10) || 0)); // Clamp between 0 and 255
    setRgbBuffer((prev) => ({ ...prev, [key]: clampedValue }));
  };

  // Apply Custom RGB Color
  const applyCustomColor = () => {
    const newColor = `rgb(${rgbBuffer.r}, ${rgbBuffer.g}, ${rgbBuffer.b})`; // Combine the RGB values
    setCustomColor(newColor); // Update the custom color state
    setBackgroundColor(newColor); // Apply it to the background color
  };

  const handleNext = async () => {
    const customizationData = {
      backgroundColor: backgroundColor === 'custom' ? customColor : backgroundColor,
      titleFont,
      titleFontSize,
      titleFontColor,
      titleShadow,
      titleBold,
      dateFont,
      dateFontSize,
      dateFontColor,
      dateBold,
      welcomeFont,
      welcomeFontSize,
      welcomeFontColor,
      welcomeBold,
      buttonColor,
      buttonGlow,
      coverPhotoUrl
    };
  
    onNext(customizationData);
  };
  
  

const renderPhotoGridPreview = () => (
  <Grid container spacing={2} justifyContent="center">
    {placeholderImages.slice(0, 2).map((image, idx) => ( // Only display the first two images
      <Grid item xs={12} key={idx}> {/* Stack two images vertically */}
        <Card>
          <CardMedia component="img" image={image} alt={`Placeholder ${idx}`} />
        </Card>
      </Grid>
    ))}
  </Grid>
);


  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {/* Upload Cover Photo - Moved to Top */} 
          <Typography variant="body1">Upload Cover Photo:</Typography>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-cover-photo"
            type="file"
            onChange={handleCoverPhotoChange}
          />
          <label htmlFor="upload-cover-photo">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>

   
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
    Background Customization
  </Typography>

  {/* Rainbow Circle */}
  <IconButton
    onClick={() => setShowColorOptions(!showColorOptions)}
    sx={{
      width: 40,
      height: 40,
      borderRadius: '50%',
      background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
      marginTop: 1,
    }}
  />

  {/* Color Options */}
  {showColorOptions && (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 2,
        marginTop: 2,
      }}
    >
      {backgroundColors.map((option, index) => (
        <Box
          key={index}
          onClick={() => setBackgroundColor(option.color)}
          sx={{
            width: 30,
            height: 30,
            backgroundColor: option.color === 'custom' ? customColor : option.color,
            borderRadius: '50%',
            boxShadow: option.color === backgroundColor ? '0 0 10px rgba(0, 0, 0, 0.5)' : '',
            cursor: 'pointer',
            border: '2px solid #ccc',
          }}
        />
      ))}



{/* Toggle RGB/HEX Inputs */}
{/* Custom Color Picker with Hex Input */ }
<Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Custom Color:</Typography>
  
  {/* Always-visible Rainbow Icon */}
  <IconButton
    onClick={() => setShowRgbInputs(!showRgbInputs)} // Toggle visibility
    sx={{
      width: 40,
      height: 40,
      borderRadius: '50%',
      background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
      cursor: 'pointer',
      marginLeft: 1,
    }}
  />
  
  {/* Color Picker and Hex Input (conditionally visible) */}
  {showRgbInputs && (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Input
        type="color"
        value={customColor}
        onChange={(e) => {
          const selectedColor = e.target.value;
          setCustomColor(selectedColor);
          setBackgroundColor(selectedColor);
        }}
        sx={{
          width: '40px',
          height: '40px',
          padding: 0,
          border: 'none',
          cursor: 'pointer',
        }}
      />

<Input
  type="text"
  value={customColor}
  onChange={(e) => {
    const hex = e.target.value;
    setCustomColor(hex); // Update as the user types
    // Validate only when the hex code is complete
    if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
      setBackgroundColor(hex); // Apply to background if valid
    }
  }}
  placeholder="#ffffff"
  sx={{
    width: '100px',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '4px',
  }}
/>

    </Box>
  )}
</Box>

    </Box>
  )}

  {/* RGB Inputs for Custom Color */}

</Box>
  
        
{/* Custom Color Picker with Hex Input */}
{backgroundColor === 'custom' && (
  <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Custom Color:</Typography>
    
    {/* Color Picker */}
    <Input
      type="color"
      value={customColor}
      onChange={(e) => {
        const selectedColor = e.target.value;
        setCustomColor(selectedColor); // Update custom color
        setBackgroundColor(selectedColor); // Apply to background
      }}
      sx={{
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        padding: 0,
        border: 'none',
      }}
    />

    {/* Hex Code Input */}
    <Input
      type="text"
      value={customColor}
      onChange={(e) => {
        const hex = e.target.value;
        // Ensure the hex is valid before setting
        if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
          setCustomColor(hex); // Update custom color
          setBackgroundColor(hex); // Apply to background
        }
      }}
      placeholder="#ffffff"
      sx={{
        width: '100px',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '4px',
      }}
    />
  </Box>
)}



<Box sx={{ marginBottom: 3 }}>
  {/* Font Type Dropdown */}
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
    Title Customization
  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
    <Select
      value={titleFont}
      onChange={(e) => setTitleFont(e.target.value)}
      sx={{
        width: '150px',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      {fonts.map((fontOption, index) => (
        <MenuItem key={index} value={fontOption.name}>
          {fontOption.name}
        </MenuItem>
      ))}
    </Select>

    {/* Font Size Controls */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Button
        variant="outlined"
        onClick={() => setTitleFontSize((prev) => `${Math.max(12, parseInt(prev) - 1)}px`)}
        sx={{
          minWidth: '36px',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        -
      </Button>
      <Typography>{parseInt(titleFontSize)}</Typography>
      <Button
        variant="outlined"
        onClick={() => setTitleFontSize((prev) => `${Math.min(50, parseInt(prev) + 1)}px`)}
        sx={{
          minWidth: '36px',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        +
      </Button>
    </Box>

    {/* Font Color Picker */}
    <IconButton
      component="label"
      sx={{
        position: 'relative',
        '&:hover': {
          '&::after': {
            content: '"Change Font Color"',
            position: 'absolute',
            top: '-24px',
            backgroundColor: '#333',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
          },
        },
      }}
    >
   <Typography
  sx={{
    fontWeight: 'bold',
    fontSize: '28px', // Slightly larger for better visibility
    background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)', // Light shadow for depth
    borderRadius: '4px', // Optional: smooth text edges
    cursor: 'pointer', // Indicate interactivity
    '&:hover': {
      transform: 'scale(1.1)', // Slight zoom on hover
      transition: 'all 0.3s ease', // Smooth animation
    },
  }}
>
  A
</Typography>



      <Input
        type="color"
        value={titleFontColor}
        onChange={(e) => setTitleFontColor(e.target.value)}
        sx={{ display: 'none' }}
      />
    </IconButton>

    {/* Bold Button */}
    <Typography
  onClick={() => setTitleBold(!titleBold)}
  sx={{
    fontWeight: 'bold',
    fontSize: '28px',
    cursor: 'pointer',
    textDecoration: titleBold ? 'underline' : 'none',
    '&:hover': {
      color: '#1976d2',
      transition: 'all 0.3s ease',
    },
  }}
>
  B
</Typography>

  </Box>
</Box>



{/* Title Shadow Intensity */}
<Typography variant="body1" style={{ marginTop: '10px' }}>Title Shadow Intensity:</Typography>
<Slider
  value={parseInt(titleShadow.split(' ')[2]) || 5} // Extract intensity
  onChange={(e, newValue) => setTitleShadow(`0px 0px ${newValue}px rgba(0, 0, 0, 0.5)`)} // Adjust intensity
  aria-labelledby="shadow-intensity-slider"
  valueLabelDisplay="auto"
  min={0}
  max={20}
/>



<Box sx={{ marginBottom: 3 }}>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
    Welcome Message Customization
  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
  <Select
    value={welcomeFont}
    onChange={(e) => setWelcomeFont(e.target.value)}
    sx={{
      width: '150px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }}
  >
    {fonts.map((fontOption, index) => (
      <MenuItem key={index} value={fontOption.name}>
        {fontOption.name}
      </MenuItem>
    ))}
  </Select>

  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Button
      variant="outlined"
      onClick={() => setWelcomeFontSize((prev) => `${Math.max(12, parseInt(prev) - 1)}px`)}
      sx={{
        minWidth: '36px',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      -
    </Button>
    <Typography>{parseInt(welcomeFontSize)}</Typography>
    <Button
      variant="outlined"
      onClick={() => setWelcomeFontSize((prev) => `${Math.min(50, parseInt(prev) + 1)}px`)}
      sx={{
        minWidth: '36px',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      +
    </Button>
  </Box>

  <IconButton component="label">
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: '28px',
        background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        cursor: 'pointer',
      }}
    >
      A
    </Typography>
    <Input
      type="color"
      value={welcomeFontColor}
      onChange={(e) => setWelcomeFontColor(e.target.value)}
      sx={{ display: 'none' }}
    />
  </IconButton>

  {/* Bold Button */}
  <Typography
    onClick={() => setWelcomeBold(!welcomeBold)} // Toggle bold state
    sx={{
      fontWeight: 'bold',
      fontSize: '28px',
      cursor: 'pointer',
      textDecoration: welcomeBold ? 'underline' : 'none',
      '&:hover': {
        color: '#1976d2',
        transition: 'all 0.3s ease',
      },
    }}
  >
    B
  </Typography>
</Box>

</Box>
<Box sx={{ marginBottom: 3 }}>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
    Date Customization
  </Typography>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
  {/* Date Font Selector */}
  <Select
    value={dateFont}
    onChange={(e) => setDateFont(e.target.value)}
    sx={{
      width: '150px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }}
  >
    {fonts.map((fontOption, index) => (
      <MenuItem key={index} value={fontOption.name}>
        {fontOption.name}
      </MenuItem>
    ))}
  </Select>

  {/* Font Size Controls */}
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Button
      variant="outlined"
      onClick={() => setDateFontSize((prev) => `${Math.max(12, parseInt(prev) - 1)}px`)}
      sx={{
        minWidth: '36px',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      -
    </Button>
    <Typography>{parseInt(dateFontSize)}</Typography>
    <Button
      variant="outlined"
      onClick={() => setDateFontSize((prev) => `${Math.min(50, parseInt(prev) + 1)}px`)}
      sx={{
        minWidth: '36px',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      +
    </Button>
  </Box>

  {/* Font Color Picker */}
  <IconButton component="label">
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: '28px',
        background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        cursor: 'pointer',
      }}
    >
      A
    </Typography>
    <Input
      type="color"
      value={dateFontColor}
      onChange={(e) => setDateFontColor(e.target.value)}
      sx={{ display: 'none' }}
    />
  </IconButton>

  {/* Bold Button */}
  <Typography
    onClick={() => setDateBold(!dateBold)} // Toggle bold state
    sx={{
      fontWeight: 'bold',
      fontSize: '28px',
      cursor: 'pointer',
      textDecoration: dateBold ? 'underline' : 'none',
      '&:hover': {
        color: '#1976d2',
        transition: 'all 0.3s ease',
      },
    }}
  >
    B
  </Typography>
</Box>

</Box>


         {/* Button Color with Color Picker */}
         <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Button Color:</Typography>
<IconButton component="label" color="primary">
  <ColorLens />
  <Input
    type="color"
    value={buttonColor}
    onChange={(e) => setButtonColor(e.target.value)}
    style={{ display: 'none' }} // Hide the input, icon will trigger it
  />
</IconButton>

<Typography variant="body1" style={{ marginTop: '10px' }}>Button Glow Intensity:</Typography>
<Slider
  value={glowIntensity}
  onChange={(e, newValue) => {
    setGlowIntensity(newValue);
    setButtonGlow(`0px 0px ${newValue}px ${buttonGlowColor}`);
  }}
  aria-labelledby="glow-intensity-slider"
  valueLabelDisplay="auto"
  min={5}
  max={50}
/>

<Typography variant="body1" style={{ marginTop: '10px' }}>Choose Button Glow Color:</Typography>
<Input
  type="color"
  value={buttonGlowColor}
  onChange={(e) => {
    const selectedColor = e.target.value;
    setButtonGlowColor(selectedColor);
    setButtonGlow(`0px 0px ${glowIntensity}px ${selectedColor}`);
  }}
  style={{ width: '50px', height: '30px', border: 'none', cursor: 'pointer' }}
/>


        </Grid>

        {/* Preview Section with iPhone Frame */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Event Preview
          </Typography>
          <Box
            sx={{
              width: 375,
              height: 812,
              border: '16px solid #000', // Changed to black iPhone frame
              borderRadius: '36px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
              background: '#ffffff',
              position: 'relative',
              overflow: 'hidden',
              flexDirection: 'column',
              padding: '0px',
            }}
          >
            {/* iPhone Top Notch */}
            <Box
              sx={{
                width: '60px',
                height: '5px',
                backgroundColor: '#c0c0c0',
                borderRadius: '10px',
                position: 'absolute',
                top: '18px',
              }}
            />
            {/* iPhone Camera */}
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '2px solid #c0c0c0',
                position: 'absolute',
                top: '10px',
                right: '95px',
                backgroundColor: '#fff',
              }}
            />
            {/* Real-Time View Container */}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                padding: '20px',
                paddingTop: '80px', // Adjust for notch
                background: backgroundColor,
              }}
            >
              {/* Circular Cover Photo */}
              <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                <img
                  src={coverPhotoUrl}
                  alt="Cover"
                  style={{
                    borderRadius: '50%',
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                    border: '5px solid #e0e0e0',
                  }}
                />
              </div>

              {/* Event Title */}
              <Typography
  variant="h5"
  style={{
    fontFamily: `${titleFont}, sans-serif`,
    fontSize: titleFontSize,
    color: titleFontColor,
    textShadow: `${titleGlow}, ${titleShadow}`, // Combine glow and shadow
    fontWeight: titleBold ? 'bold' : 'normal',
    textAlign: 'center',
    marginBottom: '10px',
  }}
>
                {eventDetails.eventName}
              </Typography>

              {/* Event Date */}
              <Typography
  variant="body2"
  style={{
    fontFamily: `${dateFont}, sans-serif`,
    fontSize: dateFontSize,
    color: dateFontColor,
    fontWeight: dateBold ? 'bold' : 'normal', // Apply bold if dateBold is true
    textAlign: 'center',
    marginBottom: '10px',
  }}
>
  {eventDetails.eventDate}
</Typography>


              {/* Welcome Message */}
              {eventDetails.welcomeMessage && (
  <Typography
    variant="body2"
    style={{
      fontFamily: `${welcomeFont}, sans-serif`,
      fontSize: welcomeFontSize,
      color: welcomeFontColor,
      fontWeight: welcomeBold ? 'bold' : 'normal', // Apply bold if welcomeBold is true
      textAlign: 'center',
      marginBottom: '20px',
    }}
  >
    {eventDetails.welcomeMessage}
  </Typography>
)}


              {/* Upload Button in Preview */}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
  variant="contained"
  style={{
    backgroundColor: buttonColor,
    marginBottom: '30px',
    width: '80%',
    boxShadow: buttonGlow, // Apply the glow effect
  }}
>
  Upload Photos/Videos
</Button>

              </Box>

              {/* Render Preview of Placeholder Images (Now Vertical) */}
              <div style={{ marginTop: '20px', padding: '10px' }}>
                {renderPhotoGridPreview()}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Navigation Buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="outlined" onClick={onBack}>Back</Button>
        <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
      </div>
    </Container>
  );
}
export default EventCustomizationForm;
